import commonAPIService from "../../common/js/common-api-service";
import systemSettingsService from "../js/system-settings-service";
import EncryptUtility from "../../utility/js/encrypt-utility";
import DisplayTextData from "../../common/display-text-data.vue";
export default {
  name: "emailProcessForm",
  props: {
    loc: String,
    showForm: Boolean,
    editId: String
  },
  data: () => ({
    projectList: [],
    processList: [],
    selectedProcess: "",
    selectedProject: "",
    deleteProcess: false,
    subProcess: "",
    newEmail: [],
    emailList: [],
    businessList: [],
    displayTextArray: [],
    isActive: true,
    disableBusiness: true,
    userId: EncryptUtility.localStorageDecrypt("userID"),
    required: [(v) => !!v || "Field is required"],
    requiredAllVaidation: [(v) => v>-1 || "Field is required"],
  }),
  async mounted() {
    this.getDataPageLoad();
    if (this.editId != 0) {
      let processEdit = await systemSettingsService.getEmailProcessDataById("get",this.userId,this.editId)
      if (processEdit) {
      
        this.newEmail = processEdit[0].EmailList.split(", ");
        this.isActive = processEdit[0].active;
      }
      this.displayTextArray = [
        { label: "Process", value: processEdit[0].process_name },
        { label: "Project", value:processEdit[0].project },
        { label: "Business", value: processEdit[0]?.business },
        { label: "Email Distribution List", value:processEdit[0].sub_process},

      ];
    }
  },
  methods: {
    async onAddInput(inputVal){
      if(inputVal?.length>2){
      let getEmailData = await systemSettingsService.getEmailList("get",inputVal);
      if (getEmailData) {
        this.emailList = getEmailData
      }}
      
    },
    emailValidationRule(value) {
      if (!value || (Array.isArray(value) && value.length === 0)) {
        // Check for empty values
        return "Email is required";
      }
      if (Array.isArray(value)) {
        // Validate each element in the array
        const isValidEmails = value.every((element) => {
          if (typeof element === "object" && element.email) {
            // If it's an object with an email property
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element.email);
          } else if (typeof element === "string") {
            // If it's a string
            return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(element);
          } else {
            // Invalid format
            return false;
          }
        });

        return isValidEmails || "Enter valid email addresses";
      } else if (typeof value === "string") {
        // If it's a single string
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || "Enter a valid email address";
      } else {
        // Invalid format
        return "Invalid email format";
      }
    },
    closeDialog() {
      this.showForm = false;
      this.selectedProcess = "";
      this.selectedProject = "";
      this.subProcess = "";
      this.newEmail = [];
      this.isActive = true;
      this.disableBusiness = true;
      this.$refs.addEmailProcessMapperForm.resetValidation();
      this.$emit("closeDialog");
    },
    async getDataPageLoad() {

      let projectData = await commonAPIService.getProject("get", this.userId);
      if (projectData?.ProjectInfo) {
        this.projectList = projectData.ProjectInfo;
      }
      let processData = await systemSettingsService.getEmailProcessDropdown("get");
      if (processData) {
        this.processList = processData;
      }
      let getEmailData = await systemSettingsService.getEmailList("get","");
      if (getEmailData) {
        this.emailList = getEmailData
      }
    },
    async onChangeProject(projId) {
      this.disableBusiness = false;
      let businessObj = {
        UserId: this.userId,
        ProjKey: projId,
        BuKey: 0, // Do not Delete
        IncludeEmptyBU: 1, // Do not Delete
        WithinSameLocation: 0, // Do not Delete
        loca_key: 0,
      };
      let businessData = await commonAPIService.postBusinessData("post", businessObj);
      this.businessList = businessData.map((x) => ({
        bu_id: x.Bu_id,
        business: x.Business,
        bu_payterm: x.pterms,
      }));
    },
    async onClickUpsert() {
      if (this.$refs.addEmailProcessMapperForm.validate()) {
        let emailArray = this.newEmail.map((item) => {
          if (typeof item === "string") {
            return item; // for strings directly in the array
          } else if (item && typeof item === "object" && "email" in item) {
            return item.email; // for objects with an 'email' property
          }
        });
        let resultString = emailArray.join(",");
        let obj = {
          process_id:this.loc=="new"? parseInt(this.selectedProcess):0,
          proj_id:this.loc=="new"? parseInt(this.selectedProject):0,
          sub_process: this.loc=="new"?this.subProcess:0,
          user_id: parseInt(this.userId),
          email_list: resultString,
          pass: this.loc=="new"?2:1,
          mapper_id:this.loc=="new"? 0:this.editId,
          active: this.isActive == true ? 1 : 0,
          bu_key:this.loc=="new"? this.selectedBusiness:0
        };
        await systemSettingsService.postEmailUpsert("post", obj, true)
        this.closeDialog()
      }
    },
    async deleteEmailProcess() {
      await systemSettingsService.deleteEmailProcess("get", this.userId, this.editId, true)
      this.deleteProcess = false
      this.closeDialog()
    }
  },
  components: {
    DisplayTextData,
  }
};
