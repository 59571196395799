import Utility from "../../../shared/utility";
import AddFavourite from "../../utility/js/addFav";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";

import EmailProcessForm from "../comp-email-process-mapper-form.vue";

export default {
  data() {
    return {
      actionList: [],
      mainPage: "",
      favouriteList: [],
      pageUrl: "",
      subPage: "",
      showAdd: false,
      showForm:false,
      loc:"",
      subPageUrl: "",
      backSlash: true,
      backSlashParent: true,
      refresh: true,
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchProcess: "",
      isActive: true,
      processData: [],
      addEmailProcessMapperDialog: false,
      selectedProcess: "",
      selectedProject: "",
      processList: [],
      projectList: [],
      subProcess: "",
      newEmail: [],
      isAddActive: true,
      selectedProcessUpdate: "",
      selectedProjectUpdate: "",
      subProcessUpdate: "",
      updateEmail: "",
      editAPIEmailParam: "",
      isAddActiveUpdate: true,
      editProcessItem: [],
      updateEmailProcessMapperDialog: false,
      deleteEmailProcessDialog: false,
      emailList: [],
      selectedEmailUpdate: false,
      selectedEmailNew: false,
      apiParameter: "",
      emailRule: [],
      editId:0,
      headersProcess: [
        {
          text: "Project",
          align: "start",
          value: "project",
          class: "primary customwhite--text",
        },
        {
          text: "Business",
          value: "business",
          class: "primary customwhite--text",
        },
        {
          text: "Process Name",
          value: "process_name",
          class: "primary customwhite--text",
        },
        {
          text: "Email DL Name",
          value: "sub_process",
          class: "primary customwhite--text",
        },

        {
          text: "Process Description",
          value: "process_desc",
          class: "primary customwhite--text",
        },
        {
          text: "Created By",
          value: "cby",
          class: "primary customwhite--text",
        },
        {
          text: "Created Date",
          value: "CreatedDate",
          class: "primary customwhite--text",
        },
        {
          text: "Actions",
          value: "actions",
          class: "primary customwhite--text",
        },
      ],

      required: [(v) => !!v || "Field is required"],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.searchProcessDetails()
  },
  mounted() {},
  methods: {
    //Reset the screen
    resetFunction() {
      this.searchProcess = "";
      this.isActive = true;
      this.processData = [];
      this.selectedEmailUpdate = false;
      this.selectedEmailNew = false;
    },
    //Page data that is required

    searchProcessDetails() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.processData = [];
      let obj = {
        user_id: parseInt(this.userId),
        SearchString: "",
        strActive: this.isActive,
      };
      this.axios
        .post("/em/email_process_mapper_search", obj)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            if (response.data.body.message != null) {
              let responsedata = [];
              responsedata = JSON.parse(response.data.body.message);
              const newArr = responsedata.map((obj) => {
                return {
                  ...obj,
                  CreatedDate: Utility.convertESTToLocal(obj.cdate),
                };
              });
              this.processData = newArr;
              this.totalRecords = this.processData.length;

              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            } else {
              this.processData = [];
              this.totalRecords = this.processData.length;
            }
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },

    //On Status change
    async searchStatus() {
      this.searchProcessDetails();
    },


    //Edit item in data table
    async editItem(item) {
     this.showForm=true,
      this.loc="edit",
      this.editId=item.mapper_id
    },
    //Cancel button in the popup
    closeDialog() {
     this.showForm=false;
     this.searchProcessDetails()
    },
    cancelDelete() {
      this.deleteEmailProcessDialog = false;
      this.updateEmailProcessMapperDialog = false;
      this.selectedProcessUpdate = "";
      this.selectedProjectUpdate = "";
      this.subProcessUpdate = "";
      this.updateEmail = "";
      this.editProcessItem = [];
      this.isAddActiveUpdate = true;
      this.$refs.updateEmailProcessMapperForm.resetValidation();
    },
    //Add or remove Favourite
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
    onClickNewProcess(){
      this.showForm=true,
      this.editId=0
      this.loc="new"
    }
  },
  components: {
    breadcrumbComp,EmailProcessForm
  },
};
